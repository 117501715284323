import {
  EPaymentEvents,
  type IPaymentPaymentCompletedEvent,
  type IPaymentPaymentFinishedEvent,
  type IPaymentPaymentOpenedEvent,
  type IPaymentPaymentStartedEvent,
  type IPaymentPresetButtonClickedEvent,
  type IPaymentPromoActivatedEvent,
} from '../types/payment.types';

export class PaymentEvents {
  static dealClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPaymentEvents.DEAL_CLICKED,
    });
  }

  static paymentCompleted(event: IPaymentPaymentCompletedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPaymentEvents.PAYMENT_COMPLETED,
      eventProperties: event,
    });
  }

  static paymentFinished(event: IPaymentPaymentFinishedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPaymentEvents.PAYMENT_FINISHED,
      eventProperties: event,
    });
  }

  static paymentOpened(event: IPaymentPaymentOpenedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPaymentEvents.PAYMENT_OPENED,
      eventProperties: event,
    });
  }

  static paymentStarted(event: IPaymentPaymentStartedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPaymentEvents.PAYMENT_STARTED,
      eventProperties: event,
    });
  }

  static presetButtonClicked(event: IPaymentPresetButtonClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPaymentEvents.PRESET_BUTTON_CLICKED,
      eventProperties: event,
    });
  }

  static promoActivated(event: IPaymentPromoActivatedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPaymentEvents.PROMO_ACTIVATED,
      eventProperties: event,
    });
  }
}
