/* eslint-disable @typescript-eslint/naming-convention */
export enum EPaymentEvents {
  DEAL_CLICKED = 'Payment - Deal Clicked',
  PAYMENT_COMPLETED = 'Payment - Payment Completed',
  PAYMENT_FINISHED = 'Payment - Payment Finished',
  PAYMENT_OPENED = 'Payment - Payment Opened',
  PAYMENT_STARTED = 'Payment - Payment Started',
  PRESET_BUTTON_CLICKED = 'Payment - Preset Button Clicked',
  PROMO_ACTIVATED = 'Payment - Promo Activated',
}

export interface IPaymentPaymentCompletedEvent {
  '$revenue': number;
  'Payment Currency': string;
  'Payment Id': string;
  'Payment Method': string;
  'Payment Sum': number;
  'Payment Type': string;
  'User Currency': string;
}

export interface IPaymentPaymentFinishedEvent {
  'Payment Currency': string;
  'Payment Id': string;
  'Payment Method': string;
  'Payment Sum': number;
  'Payment Type': string;
  'User Currency': string;
}

export enum EPaymentOpenedButtonPlaces {
  HEADER = 'header',
  PROFILE = 'profile',
  SECRET_SHOP = 'secret_shop',
  EVENT = 'event',
  CASE = 'case',
  SUCCESS_PAGE = 'success_page',
  UNSUCCESS_PAGE = 'unsuccess_page',
  NEW_CASES_BANNER = 'new_cases_banner',
  WAITING_PAGE = 'waiting_page',
  WITHDRAW_HISTORY = 'WIthdraw History',
  FOOTER = 'footer',
}

export interface IPaymentPaymentOpenedEvent {
  buttonPlace: EPaymentOpenedButtonPlaces;
}

export interface IPaymentPaymentStartedEvent {
  'Payment Currency': string;
  'Payment Id': string;
  'Payment Method': string;
  'Payment Sum': number;
  'Payment Type': string;
  'User Currency': string;
}

export interface IPaymentPresetButtonClickedEvent {
  'Button Value': number;
}

export interface IPaymentPromoActivatedEvent {
  promoName?: string;
  promoType: string;
}
